"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Achievement = exports.bettorLeaderboardOrderMap = exports.bettorMetricsTimeframeToKey = exports.defaultBettorMetricsTimeframeContext = exports.BettorAchivementType = void 0;
var BettorAchivementType;
(function (BettorAchivementType) {
    BettorAchivementType[BettorAchivementType["FirstPick"] = 0] = "FirstPick";
})(BettorAchivementType || (exports.BettorAchivementType = BettorAchivementType = {}));
function defaultBettorMetricsTimeframeContext() {
    return {
        wins: 0,
        losses: 0,
        ties: 0,
        units: 0,
        rank: 0,
        win_rate: 0,
    };
}
exports.defaultBettorMetricsTimeframeContext = defaultBettorMetricsTimeframeContext;
function bettorMetricsTimeframeToKey(input) {
    switch (input) {
        case "allTime":
            return "at";
        case "yesterday":
            return "l1d";
        case "last7Days":
            return "l7d";
        case "last30Days":
            return "l30d";
        case "pastYear":
            return "l365d";
        case "last10Games":
            return "l10g";
        case "last25Games":
            return "l25g";
        case "last25GamesLast25Days":
            return "l25g_l25d";
        case "last100Games":
            return "l100g";
        case "last100GamesLast100Days":
            return "l100g_l100d";
    }
}
exports.bettorMetricsTimeframeToKey = bettorMetricsTimeframeToKey;
exports.bettorLeaderboardOrderMap = {
    wins_asc: "wins ASC",
    wins_desc: "wins DESC",
    losses_asc: "losses ASC",
    losses_desc: "losses DESC",
    ties_asc: "ties ASC",
    ties_desc: "ties DESC",
    win_rate_asc: "win_rate ASC",
    win_rate_desc: "win_rate DESC",
    total_picks_asc: "total_picks ASC",
    total_picks_desc: "total_picks DESC",
    total_units_gained_asc: "total_units_gained ASC",
    total_units_gained_desc: "total_units_gained DESC",
};
var Achievement;
(function (Achievement) {
    Achievement[Achievement["FirstSale"] = 0] = "FirstSale";
    Achievement[Achievement["Informed"] = 1] = "Informed";
    Achievement[Achievement["BigBaller"] = 2] = "BigBaller";
    Achievement[Achievement["BackAtIt"] = 3] = "BackAtIt";
    Achievement[Achievement["HeatingUp"] = 4] = "HeatingUp";
    Achievement[Achievement["ColdStreak"] = 5] = "ColdStreak";
    Achievement[Achievement["AgainstOdds"] = 6] = "AgainstOdds";
    Achievement[Achievement["DiamondHands"] = 7] = "DiamondHands";
    Achievement[Achievement["Bookie"] = 8] = "Bookie";
    Achievement[Achievement["HelloThere"] = 9] = "HelloThere";
    Achievement[Achievement["NewFriends"] = 10] = "NewFriends";
    Achievement[Achievement["JackOfAllPicks"] = 11] = "JackOfAllPicks";
    Achievement[Achievement["Seriously"] = 12] = "Seriously";
    Achievement[Achievement["FirstPick"] = 13] = "FirstPick";
    Achievement[Achievement["Genesis"] = 14] = "Genesis";
})(Achievement || (exports.Achievement = Achievement = {}));
